import * as React from 'react';
import Layout from 'components/Layout';
import translation from 'intl';
import LanguageProvider from 'LanguageProvider';
import { graphql, HeadFC, useStaticQuery } from 'gatsby';
import GrantsIndex from 'components/pages/grants';
import SEOGraphQL from 'components/SEOGraphQL';
import { PREFIX_CANONICAL_URL } from 'components/constant';
import BreadcrumbList from 'components/structuredData/BreadcrumbList';

const pageUrl = `${PREFIX_CANONICAL_URL}/grants`;

const GrantsPage = () => {
  return (
    <LanguageProvider messages={translation}>
      <Layout headerStyle="light">
        <BreadcrumbList pageLabelId="structuredData.grants" pageUrl={pageUrl} />
        <GrantsIndex />
      </Layout>
    </LanguageProvider>
  );
};
export default GrantsPage;

export const Head: HeadFC = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      strapiGrant {
        seo {
          preventIndexing
        }
        SEO {
          metaDescription
          metaTitle
          metaSocial {
            title
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);
  return <SEOGraphQL data={data.strapiGrant} canonicalURL={pageUrl} />;
};
