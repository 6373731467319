import React, { useState } from 'react';
import { SORT } from './constant';
import GrantsCard from 'components/articleList/GrantsCard';
import { graphql, useStaticQuery } from 'gatsby';
import withConvertToFilterList from 'components/common/withConvertToFilterList';
import { SortType } from 'components/filter/type';
import { buildPaginationParams, mergeParams } from 'utils/apiUtils';
import useApi from 'utils/useApi';
import tw from 'twin.macro';
import FilterOnWeb from 'components/filter/FilterOnWeb';
import FilterOnMobile from 'components/filter/FilterOnMobile';
import { LayoutTypes } from 'components/articleList/types';
import ArticleListV2 from 'components/articleList/indexV2';

const FilterAndListingWrapper = tw.div`
  grid grid-cols-1 lg:grid-cols-[2fr_5fr] lg:min-h-[950px]
`;
const WebContainer = tw.div`
  hidden lg:block z-[2] bg-white pb-6 pt-[48px] pr-[40px]
  
`;
const MobileContainer = tw.div`
  lg:hidden z-[2] bg-white
`;

const GrantsFilterAndListing = () => {
  const [fetchParams, setFetchParams] = useState<Record<string, unknown>>(
    buildPaginationParams({
      sort: ['publishedAt:desc'],
      filters: {},
    }),
  );
  const updateFilters = (params: Record<string, unknown>) => {
    const filterParams = mergeParams(fetchParams, params);
    setFetchParams(filterParams);
    fetchData(filterParams);
  };
  const graphQlData = useStaticQuery(graphql`
    query {
      allStrapiGrantType {
        nodes {
          name
          slug
        }
      }
      allStrapiGrantLocation {
        nodes {
          name
          slug
        }
      }
    }
  `);

  const grantTypeSort = withConvertToFilterList(
    graphQlData?.allStrapiGrantType?.nodes,
    'grant_type',
    'Type',
  );
  const grantLocationSort = withConvertToFilterList(
    graphQlData?.allStrapiGrantLocation?.nodes,
    'grant_location',
    'Country',
  );

  const { data, loading, fetchData } = useApi({
    path: `/grant-articles`,
    autoFetchWithParams: fetchParams,
  });
  const handleSort = (chooseValue: string) => {
    const sortItem = SORT.items.find(({ value }) => value === chooseValue);
    if (sortItem) {
      const { strapiValue, strapiKey } = sortItem;
      updateFilters?.(
        buildPaginationParams({
          sort: [`${strapiKey}:${strapiValue}`],
        }),
      );
    }
  };

  const renderItemList = ({ id, attributes }) => {
    const {
      title,
      slug,
      cardTime,
      cardDescription,
      cardCover,
      updatedAt,
      cardSubsidyText,
    } = attributes;
    const card = {
      title,
      to: slug,
      money: cardSubsidyText,
      time: cardTime,
      description: cardDescription,
      imgSrc: cardCover.data.attributes.url,
    };
    const today = new Date().getTime();
    const articleDate = new Date(updatedAt).getTime();
    const isNew = today - articleDate <= 15778476000; // in 6 months
    return <GrantsCard key={id} {...card} isNew={isNew} />;
  };

  const mobileFilterList: SortType[] = [grantLocationSort, grantTypeSort];

  return (
    <FilterAndListingWrapper>
      <WebContainer>
        <FilterOnWeb
          sortList={SORT}
          sortByCountry={grantLocationSort}
          sortByCategory={[grantTypeSort]}
          updateFilters={updateFilters}
          handleSort={handleSort}
        />
      </WebContainer>
      <MobileContainer>
        <FilterOnMobile
          sortList={SORT}
          filterList={mobileFilterList}
          updateFilters={updateFilters}
          handleSort={handleSort}
        />
      </MobileContainer>

      <ArticleListV2
        renderListItem={renderItemList}
        data={data}
        loading={loading}
        updateFilters={updateFilters}
        layoutType={LayoutTypes.TWO_COLS}
      />
    </FilterAndListingWrapper>
  );
};

export default GrantsFilterAndListing;
