import Link from 'components/Link';
import { GrantsCardType } from 'components/types';
import React from 'react';
import { useIntl } from 'react-intl';
import tw from 'twin.macro';

interface GrantsCardProps extends GrantsCardType {}
interface DetailItemType {
  id: string;
  icon: string;
  text: string;
}

const ImgAndTagWrapper = tw.div`
  relative m-auto
`;
const NewTagLabel = tw.div`
  absolute top-0 right-0 text-[14px] font-[500] text-[#C16A1B] py-1 px-2 bg-[#F9EFCC]
`;
const CardImg = tw.img`
  h-[124px] bg-white w-full object-cover
`;
const TextWrapper = tw.div`
  group-hover:bg-hubble_blue
`;
const Title = tw.p`
  text-[18px] font-[500] my-4
`;
const DetailItemWrapper = tw.div`
  flex items-start gap-2 mb-2
`;
const ItemIcon = tw.span`
  mt-1
`;
const ItemDescription = tw.span`
  text-[14px]
`;
const StyledLink = tw(Link)`
  p-6 max-w-[400px] min-w-[320px] min-h-[380px] bg-white hover:bg-hubble_blue hover:text-white  shadow-[0px_1px_5px_rgba(41,45,55,0.15)] text-black
`;

const DetailItem = ({ icon, text }: DetailItemType) => {
  return (
    <DetailItemWrapper>
      <ItemIcon className={icon} />
      <ItemDescription>{text}</ItemDescription>
    </DetailItemWrapper>
  );
};
const GrantsCard = ({
  title,
  money,
  time,
  description,
  imgSrc,
  isNew,
  to,
}: GrantsCardProps) => {
  const { formatMessage } = useIntl();
  const detailList: DetailItemType[] = [
    {
      id: 'money',
      icon: 'fa-light fa-money-bill',
      text: money,
    },
    { id: 'time', icon: 'fa-light fa-clock', text: time },
    { id: 'description', icon: 'fa-light fa-circle-info', text: description },
  ];
  return (
    <StyledLink to={`/grants/${to}`} className="group">
      <ImgAndTagWrapper>
        <CardImg src={imgSrc} alt="grant-img" />
        {isNew && (
          <NewTagLabel>{formatMessage({ id: 'article.newLabel' })}</NewTagLabel>
        )}
      </ImgAndTagWrapper>

      <TextWrapper>
        <Title>{title}</Title>
        {detailList?.map(item => (
          <DetailItem key={item.id} {...item} />
        ))}
      </TextWrapper>
    </StyledLink>
  );
};

export default GrantsCard;
