import React from 'react';
import LanguageProvider from 'LanguageProvider/index';
import translation from './translation';
import GrantsHero from './GrantsHero';
import GrantsFilterAndListing from './GrantsFilterAndListing';

const Grants = () => {
  return (
    <LanguageProvider messages={translation}>
      <GrantsHero />
      <GrantsFilterAndListing />
    </LanguageProvider>
  );
};

export default Grants;
