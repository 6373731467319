import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import tw from 'twin.macro';

const HeroWrapper = tw.div`
  mt-[84px] px-[30px] lg:px-0 text-base_text h-[238px] flex flex-col justify-center items-center lg:justify-end lg:items-start text-center lg:text-start
`;

const Heading = tw.h1`
  text-[36px] md:text-[48px] m-0 !font-[500]
`;

const SubHeading = tw.p`
  mb-10 whitespace-pre-line
`;

const GrantsHero = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiGrant {
        nodes {
          PageDescription
          PageHeader
        }
      }
    }
  `);
  const { PageHeader, PageDescription } = data.allStrapiGrant.nodes[0];
  return (
    <HeroWrapper>
      <Heading>{PageHeader}</Heading>
      <SubHeading className="b5">{PageDescription}</SubHeading>
    </HeroWrapper>
  );
};

export default GrantsHero;
