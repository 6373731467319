import React, { useEffect, useState } from 'react';
import { Input, Select } from 'antd';
import FilterByCategory from './FilterByCategory';
import LanguageProvider from 'LanguageProvider/index';
import translation from './translation';
import { useIntl } from 'react-intl';
import { CheckedFilterListType, FilterOnWebProps } from './type';
import { buildPaginationParams } from 'utils/apiUtils';

const { Search } = Input;

const FilterOnWeb = ({
  sortList,
  sortByCountry,
  sortByCategory,
  updateFilters,
  handleSort,
}: FilterOnWebProps) => {
  const { formatMessage } = useIntl();
  const [checkedFilterList, setCheckedFilterList] =
    useState<CheckedFilterListType>({});

  const onSearch = (query: string) => {
    updateFilters?.(
      buildPaginationParams({
        filters: {
          title: {
            $containsi: query,
          },
        },
      }),
    );
  };

  const handleFilter = (valueList: string[], key: string) => {
    updateFilters?.(
      buildPaginationParams({
        filters: { [key]: { slug: { $in: valueList } } },
      }),
    );
  };

  useEffect(() => {
    Object.keys(checkedFilterList).forEach(key => {
      if (checkedFilterList[key].length > 0) {
        handleFilter(checkedFilterList[key], key);
      }
    });
  }, [checkedFilterList]);

  return (
    <div id="filter-on-web">
      <p className="text-[20px] font-[500] mb-6">
        {formatMessage({ id: 'filter.title' })}
      </p>
      <div className="flex flex-col gap-2">
        <Search
          placeholder={formatMessage({ id: 'filter.search.placeholder' })}
          allowClear
          onSearch={onSearch}
          style={{ width: '100%', color: '#C1C3C9' }}
          size="large"
        />
        <Select
          data-testid="web-sort-select"
          defaultValue={sortList?.items[0].value}
          style={{ width: '100%' }}
          onChange={value => handleSort(value)}
          options={sortList?.items}
          size="large"
        />
        {sortByCountry && (
          <Select
            data-testid="web-country-select"
            mode="multiple"
            style={{ width: '100%' }}
            onChange={e => {
              handleFilter(e, sortByCountry?.key);
            }}
            options={sortByCountry?.items}
            size="large"
            placeholder={formatMessage({
              id: 'filter.web.filter.country.placeholder',
            })}
          />
        )}
      </div>

      {sortByCategory?.map(category => (
        <FilterByCategory
          {...category}
          filterKey={category?.key}
          setCheckedFilterList={setCheckedFilterList}
          checkedFilterList={checkedFilterList}
        />
      ))}
    </div>
  );
};

const index = (props: FilterOnWebProps) => {
  return (
    <LanguageProvider messages={translation}>
      <FilterOnWeb {...props} />
    </LanguageProvider>
  );
};

export default index;
