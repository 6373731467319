import React, { useState } from 'react';
import FilterByCategoryOption from './FilterByCategoryOption';
import { CheckedFilterListType, ItemType } from './type';

interface FilterByCategoryProps {
  label: string;
  items: ItemType[];
  filterKey: string;
  setCheckedFilterList: React.Dispatch<
    React.SetStateAction<CheckedFilterListType>
  >;
  checkedFilterList: CheckedFilterListType;
}
const FilterByCategory = ({
  filterKey,
  label,
  items,
  setCheckedFilterList,
  checkedFilterList,
}: FilterByCategoryProps) => {
  const [showOptions, setShowOptions] = useState(true);

  return (
    <div className="mt-8">
      <div
        className="text-[16px] mb-4 w-[100%] flex justify-between pr-3 cursor-pointer"
        onClick={() => {
          setShowOptions(!showOptions);
        }}
      >
        <p>{label}</p>
        {showOptions ? (
          <span className="fa-light fa-chevron-down text-[12px]" />
        ) : (
          <span className="fa-light fa-chevron-right text-[12px]" />
        )}
      </div>
      {showOptions && (
        <FilterByCategoryOption
          items={items}
          setCheckedFilterList={setCheckedFilterList}
          checkedFilterList={checkedFilterList}
          filterKey={filterKey}
        />
      )}
    </div>
  );
};

export default FilterByCategory;
