import React from 'react';
import Footer from 'components/footer/';
import Header from './header';
import { HeaderStyleType } from './header/type';
import CookieConsent from './cookieConsent';

interface LayoutProps {
  children: React.ReactNode;
  headerStyle: HeaderStyleType;
  backgroundColor?: string;
  className?: string;
}
interface LayoutContainerProps {
  children: React.ReactNode;
  backgroundColor?: string;
  className?: string;
}
const LayoutContainer = ({
  children,
  backgroundColor,
  className,
}: LayoutContainerProps) => {
  return (
    <div
      className={`lg:px-[72px] xl:px-[max(120px,calc((100vh-1272px)/2))] relative bg-[${backgroundColor}] ${className}`}
    >
      <div className="max-w-[1272px] xl:min-w-[1200px] mx-auto">{children}</div>
    </div>
  );
};

const Layout = ({ children, headerStyle, backgroundColor }: LayoutProps) => {
  return (
    <>
      <CookieConsent />
      <Header headerStyle={headerStyle} />
      <LayoutContainer backgroundColor={backgroundColor}>
        <main className="overflow-hidden">{children}</main>
      </LayoutContainer>
      <div className="bg-hubble_blue">
        <LayoutContainer>
          <Footer />
        </LayoutContainer>
      </div>
    </>
  );
};

export default Layout;
