import { SortType } from 'components/filter/type';

export const SORT: SortType = {
  key: 'sort',
  label: 'Sort',
  items: [
    {
      value: 'latest',
      strapiKey: 'publishedAt',
      strapiValue: 'desc',
      label: 'Latest to oldest',
    },
    {
      value: 'oldest',
      strapiKey: 'publishedAt',
      strapiValue: 'asc',
      label: 'Oldest to latest',
    },
    {
      value: 'subsidy',
      strapiKey: 'subsidyNumber',
      strapiValue: 'desc',
      label: 'Highest subsidy',
    },
  ],
};
